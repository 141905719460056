import React from "react";
import Layout from "../components/layouts";

const Privacy = () => {
  return (
    <Layout>
      <section className="w-100 bg-rich-black pos-r desired-height-3rd">
        <div className="main-wrap mx-auto d-flx flex-dir-col j-c-c h-100 pos-r z-depth-3">
          <div className="wrapper-yt will-grow">
            <div className="maxwidth-mt mx-auto tx-c wrapper will-grow ">
              <h1 className="co-white very-largetext">Privacy Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="w-100">
        <div className="maxwidth-sl mx-auto wrapper-x wrapper-y will-grow">
          <p>
            This notice provides an explanation of the information we collect
            about you. Please read the bank’s privacy notice below carefully to
            understand our practices on how we will treat your personal data.
          </p>
          <p>
            If you have a question about something in this notice, or want to
            contact our Data Protection Officer, chat with us through the app or
            send us an email at{" "}
            <strong>
              <a href="mailto:connect@roava.app">connect@roava.app</a>
            </strong>
          </p>
          <h3 className="mediumtext">Who We Are</h3>
          <p>
            For the purpose of EU General Data Protection Regulations (GDPR);
            the controller of your personal data ‘data’ is FCMB Bank (UK)
            Limited 81 Gracechurch Street, London, EC3V 0AU in which ROAVA is a
            trading name of. We are authorised by the Prudential Regulation
            Authority and are regulated by both the Financial Conduct Authority
            and the Prudential Regulation Authority. (Financial Services
            Register number: 502704)
          </p>
          <p>
            We are registered with the UK data protection authority (the
            Information Commissioner’s Office or ICO) under number Z1726573.
          </p>
          <h3 className="mediumtext">What do we mean by personal data?</h3>
          <p>
            Name, email, phone number, information on your passport or driving
            licence number, and online or device information like IP addresses.
          </p>
          <article>
            <h2 className="largetext">1. Scope of this privacy notice</h2>
            <p>
              This privacy notice applies to you if you fall into any of the
              categories of people listed:
            </p>
            <ul>
              <li>
                <p>
                  Visitors to our website or social media pages or people who
                  interact with our digital advertisements.
                </p>
              </li>
              <li>
                <p>Anyone who applies for a ROAVA account.</p>
              </li>
              <li>
                <p>
                  ROAVA account holders, and people who have previously held a
                  ROAVA account.
                </p>
              </li>
              <li>
                <p>
                  Someone who makes or receives a payment to or from a ROAVA
                  account.
                </p>
              </li>
              <li>
                <p>
                  Representatives of a ROAVA account holder (such as those
                  holding a valid power of attorney, appointed third parties or
                  executors).
                </p>
              </li>
              <li>
                <p>
                  Anyone who corresponds with us by post, email, social media or
                  any other method.
                </p>
              </li>
            </ul>
          </article>

          <article>
            <h2 className="largetext">2. How we collect your personal data</h2>
            <p>How we collect and process data about you is described below.</p>
            <h3 className="mediumtext">
              We collect personal data about you when:
            </h3>
            <ul>
              <li>
                <p>You apply for open or use any ROAVA account</p>
              </li>
              <li>
                <p>You visit our website, or use our products or services.</p>
              </li>
              <li>
                <p>
                  We search public sources, such as news reports that may link
                  you to fraud, money laundering or crime. We also search public
                  sources to identify politically exposed people using our
                  services.
                </p>
              </li>
              <li>
                <p>
                  We also search lists of government sanctions and run searches.
                </p>
              </li>
              <li>
                <p>
                  We request personal data from third parties, whom we use to
                  check your identity and risk profile.
                </p>
              </li>
              <li>
                <p>
                  We receive personal data from regulators or law enforcement
                  agencies about you.
                </p>
              </li>
              <li>
                <p>
                  This could happen when you visit the website, apply for an
                  account, hold an account or you make a payment to an account.
                </p>
              </li>
              <li>
                <p>You make a complaint.</p>
              </li>
            </ul>
          </article>

          <article>
            <h2 className="largetext">3. How we collect your personal data</h2>
            <p>
              The table below provides details about the data we collect about
              you.
            </p>
            <h3 className="mediumtext">Data we collect, generate, and use</h3>
            <dl>
              <dt className="ff-bold">
                <strong>Contact information</strong>
              </dt>
              <dd>
                This includes names, addresses (including address history),
                email addresses and phone numbers.
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you apply for an account or communicate with us through the
                  ROAVA app.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Data used to verify your identity</strong>
              </dt>
              <dd>
                This includes information such as your full name, date of birth,
                address, login credentials, tax information, IP address,
                passport or driving licence details, or details from any other
                identity documents you provide us with.
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you apply for an account or when you have an account with us.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Your source of funds</strong>
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it: </strong>When
                  you apply for or have an account with us.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Account activity information</strong>
              </dt>
              <dd>
                This includes information about your relationship with us,
                including the products and services you use, your transactions
                and your ways of interacting with us.{" "}
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it: </strong>When
                  there is any activity on your ROAVA account.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Complaints information</strong>
              </dt>
              <dd>
                This includes details about your complaint, and the progress and
                outcome of your complaint.
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it: </strong>
                  Whenever you make a complaint.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Identification and anti-fraud data</strong>
              </dt>
              <dd>
                This includes any information about you that is linked to your
                name, addresses, phone number, email address, devices or bank
                accounts that help us build up a profile of you.{" "}
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you apply for an account or when you’re an account holder.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>
                  Information about products or services you have shown an
                  interest in
                </strong>
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you visit our website, apply for an account or hold or manage
                  an account with us.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Tracking technology information</strong>
              </dt>
              <dd>
                This includes information from cookies and similar tracking
                technologies we use.
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you visit our website or login to your account.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Communications information</strong>
              </dt>
              <dd>
                This includes details of the channels (for example, post and
                email) you have opted into to receive direct marketing and other
                communications from us.
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you apply for an account or when you are an account holder.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Due diligence and compliance data</strong>
              </dt>
              <dd>
                This includes information from investigations we conduct such as
                due diligence checks, sanctions and anti-money laundering
                checks.
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you apply for an account or when you are an account holder.
                </p>
              </dd>
            </dl>
            <h3 className="mediumtext">Sensitive Data</h3>
            <p>
              Some sensitive types of personal data have special status under
              data protection law.. Details of the main types of sensitive data
              we process and when we collect them are set out in the table
              below.
            </p>
            <dl>
              <dt className="ff-bold">
                <strong>Health information</strong>
              </dt>
              <dd>
                This includes details about particular health or disability
                requirements that you want us to be aware of, so we can help you
                make use of our services. If this applies to you, we will
                provide you with more information at the relevant time.
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you share this information with us on the by email or through
                  our website or social media pages.
                </p>
              </dd>
            </dl>
            <dl>
              <dt className="ff-bold">
                <strong>Criminal offences information</strong>
              </dt>
              <dd>
                This includes criminal offences or criminal records that we
                become aware of.{" "}
              </dd>
              <dd>
                <p>
                  <strong className="ff-bold">When we collect it:</strong> When
                  you apply for an account, make a payment.
                </p>
              </dd>
            </dl>
          </article>

          <article>
            <h2 className="largetext">4. Why we use your personal data</h2>
            <p>
              We will only use your personal data where we have a lawful reason
              to do so which includes:
            </p>
            <h3 className="mediumtext">To meet our legal obligations</h3>
            <p>
              We use your personal data to help us comply with the relevant laws
              and regulations that apply to us and our products and services,
              including laws and regulations which relate to:
            </p>
            <ul>
              <li>
                <p>the provision of banking services;</p>
              </li>
              <li>
                <p>
                  anti-money laundering, terrorist financing and the prevention
                  and detection of unlawful acts and criminal activity;
                </p>
              </li>
              <li>
                <p>consumer protection;</p>
              </li>
              <li>
                <p>
                  third party access (for example, in relation to powers of
                  attorney); and
                </p>
              </li>
              <li>
                <p>taxation.</p>
              </li>
            </ul>
            <h3 className="mediumtext">To fulfil our contract with you</h3>
            <p>
              When you become a ROAVA customer we agree to provide you with
              certain services. We will use your personal data where it is
              necessary for us to meet the obligations in our contract with you,
              such as to apply interest payments, facilitate payments to and
              from your account and produce annual statements.
            </p>
            <h3 className="mediumtext">
              When using your data is necessary for our legitimate interests
            </h3>
            <p>
              {" "}
              This might happen where it is in our legitimate business interests
              to analyse how you interact with our services so that we can
              continue to develop and improve our website and products. It is
              necessary for our legitimate interests to use your personal data
              to do the following:
            </p>
            <ul>
              <li>
                <p>
                  Conduct all necessary checks that help us to comply with the
                  law and manage our risks appropriately,{" "}
                </p>
              </li>
              <li>
                <p>
                  Contact you when we need to provide you with information about
                  our services.
                </p>
              </li>
              <li>
                <p>
                  Fulfil our regulatory obligations and business requirements by
                  keeping records of calls, correspondence and our business
                  activities and archiving and backing up data.
                </p>
              </li>
              <li>
                <p>
                  Analyse market trends and user preferences to test and
                  evaluate our existing products, and research potential new
                  services.
                </p>
              </li>
              <li>
                <p>
                  Help train our staff so that we can maintain the quality of
                  our products and services..
                </p>
              </li>
              <li>
                <p>Maintain the security of your account and our services.</p>
              </li>
              <li>
                <p>
                  Produce management information and reports to help us identify
                  potential issues., For example, monitoring and managing
                  financial, reputational, conducting audits of our business,
                  liaising with our regulators, protecting data used by our
                  business and establishing, enforcing and defending against
                  legal claims.
                </p>
              </li>
              <li>
                <p>Protect you against fraud, money laundering and crime.</p>
              </li>
              <li>
                <p>
                  Understand, maintain and improve the performance of our
                  business.
                </p>
              </li>
            </ul>
          </article>

          <article>
            <h2 className="largetext">
              5. Who we share your personal data with and why
            </h2>
            <p>
              We share your personal data with other members of FCMB group and
              with third party service providers, who act on our instructions
              and on our behalf to help provide you with our products and
              services.
            </p>
            <h3 className="mediumtext">Why we share your data</h3>
            <dl>
              <dt>
                <p>To provide services to you, such as processing payments you make and to fulfil our contractual obligations to you.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong> Banks, anyone who acts as a payee on an account and third party service providers.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>We may process your information and store it on servers managed by our hosting providers in order to provide the services .</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong> When you open your account.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>To help detect, investigate and prevent fraud, money laundering or crime.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong> Law enforcement agencies, fraud prevention agencies and third party service providers who may share it with others for the same reason.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>When we are required to do so by law, or when we’re asked to respond to requests from law enforcement agencies or regulators.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong> Law enforcement agencies, government, regulators, courts, dispute resolution bodies and tribunals.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>To verify your identity.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong> Credit reference agencies and third party services providers.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>If our business, or part of it, is sold or reorganised, including any transfer or potential transfer of any of our rights or duties under our agreement with you.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong> Prospective or actual purchasers or transferees and their advisors.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>We may need to share your data with tax authorities to comply with both UK and international obligations.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong> HMRC, which may share your data with tax authorities in other countries.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>Other extenuating circumstances, such as litigation or asserting or defending our legal rights and interests or if we need to get legal advice.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong>  Any other person or company we reasonably think we need to. For example, with our lawyers.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>To send direct marketing messages to you.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong>  Any third parties which provide marketing services to you on our behalf.
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <p>For market research purposes and to identify customer trends.</p>   
              </dt>
              <dd>
                <p>
                  <strong className="ff-bold">Who we share your data with:</strong>  Market research agencies acting on our behalf.
                </p>
              </dd>
            </dl>
          </article>
        
          <article>
            <h2 className="largetext">
            6. How long will we keep your data
            </h2>
            <p>
              We have a regulatory obligation to retain your data for a minimum of 6 years after which time it will be destroyed. We may hold onto your data for an indefinite period where there  may be legal ramifications if the data is deleted.
            </p>
          </article>
        
          <article>
            <h2 className="largetext">
            7. Transfers outside the UK and the EEA
            </h2>
            <p>
            We transfer your personal data outside the UK and the European Economic Area (EEA) to provide our products and services.
            </p>
            <p>
            We may transfer information to servers located across a number of secure data centres in the UK and EEA. Our server environment is highly secure and there is very limited personnel access. Any information will be encrypted “at rest” (in other words, on being stored).            </p>
            <p>
            Your information may be transferred to, and stored at, a destination outside the UK and EEA as well as processed by staff operating outside the UK and EEA who work for them. We will ensure that suitable safeguards are in place before your information is transferred outside the UK and EEA as required by law and we will take all steps reasonably necessary to ensure that information about you is treated securely and in accordance with this notice. Where recipients of your personal data are located in countries that are outside the UK and EEA and do not provide for the same level of data protection as considered adequate in the UK and European Union, we take appropriate measures to ensure that your data is protected. For example, where required, our service providers and affiliates sign standard contractual clauses approved by the European Commission, or other supervisory authorities.            </p>
            <p>
            If you are outside the UK and EEA and make payments or send messages, or you are in the UK and EEA and make payments or send messages outside the UK and EEA, we may process payments through other institutions and payment systems. They may have to process and store information about you in connection with their own regulations. Please note that the standards to which they adhere may not be as stringent as those in the UK and EEA.            </p>
            <p>
            The transmission of your information through the Internet is not 100% secure. Although we will do our best to protect your information, we cannot guarantee the security of information about you transmitted to us and so any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.            </p>
          </article>

          <article>
            <h2 className="largetext">
            8. How we keep your data secure
            </h2>
            <p>ROAVA takes the security of your personal data seriously and provides training and education about privacy and data security to all our staff. We seek to limit access to your personal data to authorised employees, agents, contractors or vendors. We have also implemented physical, electronic and procedural measures designed to protect your data from loss and destruction as well as unauthorised access, disclosure and alteration.</p>
          </article>

          <article>
            <h2 className="largetext">
            9. Your Rights
            </h2>
            <h3 className="mediumtext">Access</h3>
            <p>You have the right to be provided with information about the personal data we have about you and details of how we use it, as well as the right to receive a copy of such personal data. Your right of access can be exercised as follows:</p>
            <p>If you are a customer of ROAVA, please make a request through the App.</p>
            <p>If you are not a customer, please email ROAVA at connect@roava.app</p>
            <h3 className="mediumtext">Rectification</h3>
            <p>You have the right to request that we rectify your information if it is incomplete or inaccurate. </p>
            <h3 className="mediumtext">Erasure</h3>
            <p>You have the right to request that we erase your personal data in certain circumstances. We may continue to store your personal data if we are entitled or required to retain it.</p>
            <h3 className="mediumtext">Restriction of processing</h3>
            <p>You can request that we restrict use of your personal data in certain circumstances. There may be situations where we are entitled to continue using your information and/or to refuse your request.</p>
            <h3 className="mediumtext">Data Portability</h3>
            <p>You can ask to have transferred elsewhere information about you that you have provided to us.</p>
            <h3 className="mediumtext">Object</h3>
            <p>You can ask us to stop using all or some of your information or to limit our use of it.</p>
            <h3 className="mediumtext">Withdrawal of consent</h3>
            <p>Where we rely upon your consent to use your personal data, you have the right to withdraw consent at any time. This does not affect the lawfulness of the processing of your data carried out with your consent before the withdrawal.  It is up to you whether to provide us with your personal data but in  some circumstances we will not be able to open or maintain your account if you fail to provide certain information.</p>
            <h3 className="mediumtext">Complaints</h3>
            <p>If you have a complaint about how we use your personal information, please contact us through the app or send an email to connect@roava.app and we will do our best to fix the problem. You can also reach our Data Protection Officer in these ways.</p>
            <p>If you are still not happy, you can refer your complaint with a data protection supervisory authority in the EU country you live or work, or where you think a breach has happened. The UK’s supervisory authority is the Information Commissioner’s Office (ICO). For more details, you can visit their website at ico.org.uk</p>
          </article>

          <article>
            <h2 className="largetext">
            10. Updates to this notice</h2>
            <p>We will update this privacy notice to reflect these changes wherever necessary and we will give you notice of any significant changes.</p>
          </article>

          <article>
            <h2 className="largetext">
            Contact Us</h2>
            <p>For issues, inquiries, queries or concerns regarding your data or this privacy statement, you can contact us by submitting a request to - connect@roava.app</p>
            <h3 className="mediumtext">Consent</h3>
            <p>I consent to the details of this Privacy notice</p>
            <p>I consent to being kept up to date about ROAVA products and contacted for marketing purposes </p>
          </article>

        </div>
      </section>
    </Layout>
  );
};

export default Privacy;
